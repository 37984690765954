<script setup>
// Header and footer components are based on the following tutorial:
// https://stackblitz.com/edit/nuxt-ultimate-tutorial-part-3?file=README.md

const storyblokApi = useStoryblokApi();
const { locale } = useI18n();

const localePath = useLocalePath();
const query = useRoute().query;

const topNavigation = await useAsyncStoryblok('global/top-navigation', {
    version: 'published',
    resolve_links: 'url',
    language: locale.value,
});

const blok = ref(topNavigation.value.content.global[0]);
</script>

<template>
    <!-- Block start -->
    <header class="sticky top-0 z-50">
        <nav
            class="bg-white border-gray-200 px-4 lg:px-6 py-2.5 dark:bg-midnight-blue-dark shadow-sm"
        >
            <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
                <NuxtLink
                    :to="{ path: localePath('/home'), query }"
                    class="flex items-center"
                    :aria-label="blok.logoAlt || 'Tiptapp Logo'"
                >
                    <img
                        :src="blok.logo.filename"
                        class="mr-3 h-7 sm:h-9 dark:hidden"
                        :alt="blok.logoAlt || 'Tiptapp Logo'"
                    />
                    <img
                        :src="blok.logoDark.filename"
                        class="mr-3 h-7 sm:h-9 hidden dark:block"
                        :alt="blok.logoAlt || 'Tiptapp Logo'"
                    />
                </NuxtLink>
                <div class="flex items-center lg:order-2">
                    <span class="hidden mx-2 w-px h-5 bg-gray-200 lg:inline lg:mx-3"></span>
                    <StoryblokComponent :blok="blok.downloadCta[0]" class="sm:hidden mr-2" />
                    <LanguageDropdown id="header" class="hidden" />
                    <ToggleTheme class="hidden" />

                    <button
                        data-collapse-toggle="mobile-menu"
                        type="button"
                        class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700"
                        aria-controls="mobile-menu"
                        aria-expanded="false"
                    >
                        <span class="sr-only">Open main menu</span>
                        <svg
                            class="w-6 h-6"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                        <svg
                            class="hidden w-6 h-6"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill-rule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clip-rule="evenodd"
                            ></path>
                        </svg>
                    </button>
                </div>
                <div
                    id="mobile-menu"
                    class="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1"
                >
                    <ul class="flex flex-col mt-4 font-medium lg:flex-row lg:space-x-8 lg:mt-0">
                        <StoryblokComponent
                            v-for="item in blok.links"
                            :id="item._uid"
                            :key="item._uid"
                            :blok="item"
                        />
                    </ul>
                    <div class="mt-4">
                        <LanguageDropdown id="mobile-header" class="sm:hidden" />
                        <ToggleTheme class="sm:hidden" />
                    </div>
                </div>
            </div>
        </nav>
    </header>
</template>
